import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessaoLayoutComponent } from './sessao-layout/sessao-layout.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { AvaliacaoLayoutComponent } from './avaliacao-layout/avaliacao-layout.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { Router, RouterModule } from '@angular/router';
import { CustomMaterialModule } from 'src/app/core/modules/material.module';
import { PreloadingSpinnerModule } from '../preloading-spinner/preloading-spinner.module';



@NgModule({
  declarations: [SessaoLayoutComponent,HomeLayoutComponent,AvaliacaoLayoutComponent,AdminLayoutComponent],
  entryComponents:[SessaoLayoutComponent,HomeLayoutComponent,AvaliacaoLayoutComponent,AdminLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomMaterialModule,
    PreloadingSpinnerModule,
  ]
})
export class LayoutsModule { }
