import { Injectable } from "@angular/core";
import { MatDialog } from '@angular/material';
import { PendenciaColetasDialogComponent } from './pendencia-coletas-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PendenciaColetasDialogService {
  constructor(public dialog: MatDialog) {}

  pendenciaColetaDialog(coleta){
    const dialogRef = this.dialog.open(PendenciaColetasDialogComponent, {
      width: '1200px',
      data: coleta
    });
    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;
  }
}
