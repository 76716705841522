import { NgControl } from '@angular/forms';
import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[formControlName][telefoneMask]'
  })
  export class TelefoneMask {

    constructor(public model: NgControl) {}

    
  @HostListener('input', ['$event'])
  onModelChangeInput(event) {
    this.onInputChange(event, false);
  }

  @HostListener('blur', ["$event"])
  onModelChangeOutput(event) {
    this.onInputChange(event, false);
  }

  @HostListener('change', ["$event"])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('ngModelChange', ["$event"])
  onModelNgChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event, true);
  }
  
    onInputChange(event, backspace) {
      if(event.target != null){
      // remove all mask characters (keep only numeric)
   
     // var newVal = event.target.value.replace(/\D/g, '');
      var newVal = event.target.value.match(/\d/g);
      newVal = newVal.substring(0, 10);
      
      // special handling of backspace necessary otherwise
      // deleting of non-numeric characters is not recognized
      // this laves room for improvement for example if you delete in the 
      // middle of the string
      if (backspace) {
        newVal = newVal.substring(0, newVal.length);
        } 
  
      // don't show braces for empty value
      if (newVal.length == 0) {
        newVal = '';
      } 
      // don't show braces for empty groups at the end
       else {
        newVal = this.convertTelefone(newVal);
      }
      // set the new value
      this.model.valueAccessor.writeValue(newVal);
      this.model.control.setValue(newVal);  
      this.model.control.updateValueAndValidity(); 
    
    }  
    
    }

    
    convertTelefone(num) {
      if (num) {
          num = num.toString();
         // num = num.replace(/\D/g, "");
        
          switch (num.length) {
        
              case 1:
                  num = num.replace(/(\d+)/, "$1");
                  break;
              case 2:
                  num = num.replace(/(\d+)/, "$1");
                  break;
              case 3:
                  num = num.replace(/(\d+)/, "$1");
                  break;
              case 4:
                  num = num.replace(/(\d{4})/, "$1");
                  break;
              case 5:
                  num = num.replace(/(\d{4})(\d+)/, "$1-$2");
                  break;
              case 6:
                  num = num.replace(/(\d{4})(\d+)/, "$1-$2");
                  break;
              case 7:
                  num = num.replace(/(\d{4})(\d+)/,"$1-$2");
                  break;
              case 8:
                  num = num.replace(/(\d{4})(\d{4})/, "$1-$2");
                  break;
              case 9:
                  num = num.replace(/(\d{5})(\d{4})/, "$1-$2");
                  break;
          }
      }
      return num;
   }
  }