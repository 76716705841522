import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeLayoutComponent } from './components/layouts/home-layout/home-layout.component';
import { AuthGuard } from './_auth/auth.guard';
import { Error404Component } from './components/error404/error404.component';
import { SessaoLayoutComponent } from './components/layouts/sessao-layout/sessao-layout.component';
import { AvaliacaoLayoutComponent } from './components/layouts/avaliacao-layout/avaliacao-layout.component';


const routes: Routes = [
  {  path: '', redirectTo: 'sessao', pathMatch: 'full' },

  {  path: '',  component: SessaoLayoutComponent, children: [ { path:'sessao', loadChildren: "./components/sessao/sessao.module#SessaoModule" } ] },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [ { path: "home", loadChildren: "./components/home/home.module#HomeModule" } ]  },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','AdminSystem','UserSystem','AdminPosto']}, children: [ { path:'coletor', loadChildren: "./components/coletor/coletor.module#ColetorModule" } ] },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','AdminSystem','UserSystem','AdminPosto']}, children: [ { path:'empresa', loadChildren: "./components/empresas/empresas.module#EmpresasModule"  } ] },

  {  path: "perfil", loadChildren: "./components/perfil/perfil.module#PerfilModule" },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','AdminSystem','UserSystem']}, children: [ { path: "logistica", loadChildren: "./components/logistica/logistica.module#LogisticaModule" } ]  },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','AdminSystem','UserSystem']}, children: [ { path: "recebimento", loadChildren: "./components/recebimento/recebimento.module#RecebimentoModule" } ]  },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','AdminSystem','UserSystem']}, children: [ { path: "postosdecoleta", loadChildren: "./components/credenciamento/credenciamento.module#CredenciamentoModule" } ]  },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','AdminSystem']}, children: [ { path: "laudos", loadChildren: "./components/emissao-laudo/emissao-laudo.module#EmissaoLaudoModule" } ]  },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','AdminSystem']}, children: [ { path: "renach", loadChildren: "./components/renach/renach.module#RenachModule" } ]  },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','AdminSystem']}, children: [ { path: "notafiscal", loadChildren: "./components/nota-fiscal/nota-fiscal.module#NotaFiscalModule" } ]  },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','AdminSystem', 'ScreenFinance']}, children: [ { path: "financeiro", loadChildren: "./components/financeiro/financeiro.module#FinanceiroModule" } ]  },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard],  children: [ { path:'coletas', loadChildren: "./components/coletas/coletas.module#ColetasModule"  } ] },

  {  path: '',  component: AvaliacaoLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','ColetorPosto']},  children: [ { path:'prova', loadChildren: "./components/avaliacao/avaliacao.module#AvaliacaoModule" } ] },

  {  path: '',  component: HomeLayoutComponent, canActivate: [AuthGuard], data:{roles:['DebuggerSystem','AdminSystem','UserSystem']}, children: [ { path: "areatecnica", loadChildren: "./components/area-tecnica/area-tecnica.module#AreaTecnicaModule" } ]  },

  {  path: '',  component: HomeLayoutComponent, children: [ { path: '**', component: Error404Component} ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
