import {
    trigger,
    animate,
    transition,
    style,
    query
  } from '@angular/animations';
  
  export const fadeOutAnimation = trigger('fadeInOut', [
    transition(':leave', [   // :leave is alias to '* => void'
    style({ opacity: 1 }),
    animate(300, style({ opacity: 0 }))
  ]),
    transition(':enter', [   // :enter is alias to 'void => *'
      style({ opacity: 0 }),
      animate(300, style({ opacity: 1 }))
    ]),
 
  ])
  
  