import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CancelarColetasDialogComponent } from './cancelar-coletas-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class CancelarColetasDialogService {
	constructor(public dialog: MatDialog) {}

	cancelarColetaDialog(id_coleta: number) {
		const dialogRef = this.dialog.open(CancelarColetasDialogComponent, {
			width: '450px',
			data: id_coleta,
		});
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}
}
