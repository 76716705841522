import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material';
import { ChangePasswordDialogComponent } from './change-password-dialog.component';


@Injectable()
export class ChangePasswordDialogService {

    title = 'Alterar a senha do usuário';

    constructor(public dialog: MatDialog) {}


    openDialog(){
      const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
        width: '550px'
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;

    }
  
}