import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionStorageControle } from '../../core/session-storage-controle';
import { CardComponent } from './card/card.component';


@NgModule({
  declarations: [CardComponent],
  exports: [CardComponent],
  entryComponents: [CardComponent],
  imports: [
    CommonModule,
  ],
  providers: [SessionStorageControle]
})
export class AnimationsModule { }