import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  clear(){
  	this.toastr.clear();
  }
  
  showSuccess(message, title){
  	this.toastr.success(message, title,{
      timeOut : 2000
    })
  }

  showInfo(message, title){
  	this.toastr.info(message, title,{
      timeOut : 2000
    })
  }

  
  showError(message, title){
  	this.toastr.error(message, title),{
      timeOut : 2000
    }
  }

  
  showWarning(message, title){
  	this.toastr.warning(message, title,{
      timeOut : 2000
    })
  }


  
  showSuccessWithTimeout(message, title, timespan){
    this.toastr.success(message, title ,{
      timeOut : timespan
    })
  }

  showHTMLMessage(message, title){
    this.toastr.success(message, title, {
      enableHtml : true
    })
  }
  
}
