import { Injectable } from '@angular/core';
import { BaseServicePadrao } from '../core/base-service-padrao';
import { Http } from '@angular/http';
import { timeout, map, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ColetasRecoletaService extends BaseServicePadrao {
	urlBase: string;
	constructor(private http: Http) {
		super(http, 'coletasRecoleta');
	}

	getByParam(param: string, value: string | number) {
		this.checkToken();
		return this.http.get(this.urlBase + '/getByParam/' + param + '/' + value, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	getColetasViewRecoletas(conteudo: any) {
		this.checkToken();
		return this.http.post(this.urlBase + '/getColetasViewRecoletas', conteudo, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	postRecoleta(conteudo: any) {
		this.checkToken();
		return this.http.post(this.urlBase + '/postRecoleta', conteudo, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}
}
