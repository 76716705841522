import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotificationService } from 'src/app/core/notification/notification-service.service';

import { ColetasService } from 'src/app/services/coletas.service';

@Component({
  selector: 'app-estornar-coletas-dialog',
  templateUrl: './estornar-coletas-dialog.component.html',
  styleUrls: ['./estornar-coletas-dialog.component.scss']
})
export class EstornarColetasDialogComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();

  hoje = new Date();

  formaEstornoList = [
    'Compensado',
    'Em valor',
    'Em fechameto'
  ]

  public formulario: FormGroup = new FormGroup({
    'id': new FormControl(this.coleta.id_cancelamento.id, Validators.required),
    'id_perfil_estorno': new FormControl(parseInt(sessionStorage.getItem("accredited")), Validators.required),
    'data_estorno': new FormControl(null, Validators.required),
    'valor_estorno': new FormControl(null, Validators.required),
    'forma_estorno': new FormControl(null, Validators.required),
  });

  constructor(
    public dialogRef: MatDialogRef<EstornarColetasDialogComponent>,
    public changeRef: ChangeDetectorRef,
    private coletasService: ColetasService,
    private toastr: NotificationService,
    @Inject(MAT_DIALOG_DATA) public coleta: any) {
  }

  ngOnInit() {
    this.formulario.get('data_estorno').setValue(new Date());
    console.log(this.coleta)
    this.formulario.get('valor_estorno').setValue(parseFloat(this.coleta.valorAnalise) + parseFloat(this.coleta.valorColeta))
  }

  onYesClick(): void {
    this.coletasService.estornar(this.formulario.value).subscribe(retorno => {
      if (retorno.httpStatus === 200) {
        this.toastr.showSuccess("O estorno foi aplicado com sucesso", "Sucesso");
        this.event.emit(true);
        this.dialogRef.close();
      } else {
        this.toastr.showError("Erro ao estornar coleta", "Erro");
      }
    });
  }

  onNoClick(): void {
    this.event.emit(false);
    this.dialogRef.close();
  }
}
