import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material';
import { PushDialogComponent } from './push-dialog.component';


@Injectable()
export class PushDialogService {

    title = 'Push title';

    constructor(public dialog: MatDialog) {}


    openDialog(){
      const dialogRef = this.dialog.open(PushDialogComponent, {
        width: '550px',
        height: '350px',
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;

    }
  
}