export class SessionStorageControle {
    constructor(){}

    salvar(chave: string, valor: any){
        sessionStorage.setItem(chave, valor);
    }

    carregar(chave: string){
        return sessionStorage.getItem(chave);
    }

    carregarPermissao(codigoTela: number){                        
        let telas: any [] = [];        
        telas = JSON.parse(this.carregar("telas"));        
        let result: any;                
        telas.forEach(element =>{
            let tela = element;
            if(tela.codigo == codigoTela)
                result = tela.permissoes;
        });
        return result;
    }

    salvarMenu(valor: any){       
        //verifica se já existe telas e deleta elas pra adicionar o novo json                 
        sessionStorage.removeItem("menu");

        let menu = valor;        
        sessionStorage.setItem("menu", JSON.stringify( menu ));
    }

    temPermissao(codigoTela: any){
        let telas: any [] = [];        
        telas = JSON.parse(this.carregar("telas"));        
        let result: boolean = false;
                
        telas.forEach(element =>{
            let tela = element;
            if(tela.codigo == codigoTela)
                result = true;
        });        
        return result;
    }
}