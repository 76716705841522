import { Component, OnInit, Inject, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>, 
    public changeRef: ChangeDetectorRef,

    @Inject(MAT_DIALOG_DATA) public message: string) { }

    public formulario: FormGroup = new FormGroup({
      'mensagem': new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(500)]),
      'retorno': new FormControl(false)
    })

  ngOnInit() {
    
  }



  onYesClick(){
    this.formulario.get('retorno').setValue(true);
    this.event.emit(this.formulario.value);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.formulario.get('retorno').setValue(false);
    this.event.emit(this.formulario.value); 
    this.dialogRef.close();
  }
}
