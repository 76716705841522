import { Injectable } from "@angular/core";

import { Http, Headers, RequestOptions } from "@angular/http";
import { map } from "rxjs/operators";
import { BaseServicePadrao } from "../core/base-service-padrao";
import { AuthService } from '../_auth/auth.service';



@Injectable()
export class MenuService extends BaseServicePadrao{

    constructor(private http: Http){
        super(http, 'menu');
    }

    consultarMenu(status: number){
        //Atualiza Token
        this.token = window.sessionStorage.getItem('token_type')+" "+window.sessionStorage.getItem('token') ;
        this.headers = new Headers(
            { 'Content-Type': 'application/json', 'Authorization': this.token });
        this.options = new RequestOptions({ headers: this.headers });
        return this.http.get(this.urlBase + "/consultar/"+status, this.options)
        .pipe(map(dados => dados.json()));
    }

    consultaTelaCodigo(codigoTela:number){
        return this.http.get(this.urlBase+"/consultatela/"+codigoTela, this.options)
        .pipe(map(dados => dados.json()));
    }
}