import { Component, OnInit,  ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeOutAnimation } from '../../../core/animations';
const fadeInTimeMS = 300

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  animations: [fadeOutAnimation]

})
export class AdminLayoutComponent implements OnInit  {
  loading: boolean = true;

  constructor(
   private router: Router) {

 }
   ngOnInit(){
    this.functionLoader();
  }
  
  
  
  functionLoader() {  
    setTimeout (() => {
      this.loading = false;
     // console.log("Loading: "+ this.loading);
      }, 1300);
  }
 

}
