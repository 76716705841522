import { ColetorComponent } from './../../coletor/coletor.component';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { fadeOutAnimation } from 'src/app/core/animations';
import { AuthService } from 'src/app/_auth/auth.service';
import { SessionStorageControle } from 'src/app/core/session-storage-controle';
import { BaseComponentPadrao } from 'src/app/core/base-component-padrao';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { PushDialogService } from 'src/app/core/shared/push-dialog/push-dialog.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
  animations: [
    trigger('menuAnimation', [
      state('false', style({
        'opacity': '0',
        'transform': 'translate(-50px)',
        'transition': 'width 5s',
        'display': 'none',

      })),
      state('true', style({
        'transform': '*',
        'transition': 'width 5s',
        'display': 'block',
        'opacity': '1',
      })),
      transition(
        'false => true', [
        style({ opacity: 0 }),
        animate('150ms ease')]
      ),
      transition(
        'true => false', [
        style({ opacity: 1 }),
        animate('150ms ease')]
      ),
    ]),]
})
export class HomeLayoutComponent extends BaseComponentPadrao implements OnInit, AfterViewInit {
  loading: boolean = true;

  hidden = true;

  routerLinkActive: string | string[]
  public namePerson: string;
  public menus: any[] | string[];
  public submenus: any[] | String;
  public subm: any[];
  public id_perfil: string;

  public mainMenu: boolean = true;
  public subMenu: boolean = false;

  public menuAnimation: boolean = true;
  public subMenuAnimation: boolean = false;

  public menuDisplay: 'block' | 'none' = 'none';
  public subMenuDisplay: 'block' | 'none' = 'none';

  public menuStart(): void {
    this.menuDisplay = 'block';
  }

  public menuDone(): void {
    this.menuDisplay = (this.menuAnimation ? 'block' : 'none');
  }


  public subMenuStart(): void {
    this.subMenuDisplay = 'block';
  }

  public subMenuDone(): void {
    this.subMenuDisplay = (this.subMenuAnimation ? 'block' : 'none');
  }

  constructor(private sessionStorageControle: SessionStorageControle,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService,
    private pushService: PushDialogService,
    private sessao: AuthService) {
    super();
  }


  ngOnInit() {
    setTimeout(() => {
      this.functionLoader();
    }, 150);
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.functionLoader();
    }, 150);

  }

  logout() {
    this.authenticationService.revoke().subscribe(
      dados => {

        if (dados.httpStatus === 200) {
          this.toastrService.success("Deslogado com sucesso", "Sucesso")
          this.sessao.logout();

        }
      });
  }

  functionLoader() {

    if (this.sessionStorageControle.carregar('menu') != undefined) {
      this.menus = [] = JSON.parse(this.sessionStorageControle.carregar('menu'));
    }
    this.id_perfil = this.sessionStorageControle.carregar("perfil");
    //    console.log("Loading: "+ this.loading);
    this.namePerson = sessionStorage.getItem('name');

      setTimeout(()=>{
        if(this.menus != undefined){
          this.loading = false;
        } else{
            this.functionLoader();
          }
      },500);


  }

  submenu(index: number) {

    this.subm = this.menus.filter(state => (state.id.toString().indexOf(index)) === 0);
    //this.subm = this.menus.filter(state => (state.id.indexOf(index)) === 0); trecho ocorerndo erro na escolha dos submenus
    this.submenus = [] = this.subm[0].id_pai;
    this.mainMenu = !this.mainMenu;
    this.menuAnimation = false;
    setTimeout(() => {

      this.subMenu = !this.subMenu;
      this.subMenuAnimation = true;
    }, 500);

  }

  push($event) {
    console.log($event)
    this.pushService.openDialog().subscribe();
  }

  submenuBack() {
    this.subMenu = !this.subMenu;
    this.subMenuAnimation = false;
    setTimeout(() => {
      this.mainMenu = !this.mainMenu;
      this.menuAnimation = true;
      this.submenus = [];
    }, 500);

  }

}
