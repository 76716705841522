import { Injectable } from '@angular/core';
import { BaseServicePadrao } from '../core/base-service-padrao';
import { Http } from '@angular/http';
import { timeout, map, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class EmailService extends BaseServicePadrao {
	urlBase: string;
	constructor(private http: Http) {
		super(http, 'email');
	}

	notifyPendencia(id_coleta: number) {
		this.checkToken();
		return this.http.get(this.urlBase + '/notifyPendencia/' + id_coleta, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	notifyApplyPendencia(id_coleta: number) {
		this.checkToken();
		return this.http.get(this.urlBase + '/notifyApplyPendencia/' + id_coleta, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}
}
