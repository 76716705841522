import { Component, OnInit, Inject, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-envio-confirmation-dialog',
  templateUrl: './envio-confirmation-dialog.component.html',
  styleUrls: ['./envio-confirmation-dialog.component.scss']
})
export class EnvioConfirmationDialogComponent implements OnInit {

  public formulario: FormGroup = new FormGroup({
    'tipo': new FormControl(null, Validators.required),
    'send': new FormControl(null)
  });

  constructor(
    public dialogRef: MatDialogRef<EnvioConfirmationDialogComponent>,
    public changeRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ngOnInit() {
    //
  }



  get tipagem(){
    return this.formulario.get('tipo').value;
  }

  event: EventEmitter<any> = new EventEmitter();

  change(select: number){
    let tipo = this.formulario.get('tipo');
    if( select ){
      tipo.setValidators([Validators.required]);
    } else {
      tipo.clearValidators();
    }
    tipo.updateValueAndValidity();
  }

  onYesClick(){
    this.formulario.get('send').setValue(true);
    this.event.emit(this.formulario.value);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.event.emit(false);
    this.dialogRef.close();
  }

}
