import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloadingSpinnerComponent } from './preloading-spinner.component';
import { FormsModule } from '@angular/forms';
import { PipeModule } from 'src/app/core/pipe/pipe.module';
import { TextMaskModule } from 'angular2-text-mask';
import { CustomMaterialModule } from 'src/app/core/modules/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipeModule,
    TextMaskModule,
    CustomMaterialModule,
    
  ],
  declarations: [PreloadingSpinnerComponent],
  exports:[PreloadingSpinnerComponent]
})
export class PreloadingSpinnerModule { }
