import { Component, OnInit, Inject, ChangeDetectorRef, EventEmitter, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'app-push-dialog',
  templateUrl: './push-dialog.component.html',
  styleUrls: ['./push-dialog.component.scss']
})
export class PushDialogComponent implements OnInit, AfterViewInit {
  
  public event: EventEmitter<any> = new EventEmitter();
  public messages: any;
  constructor(
    public dialogRef: MatDialogRef<PushDialogComponent>, 
    public changeRef: ChangeDetectorRef) { }


  ngOnInit() {
    this.messages = JSON.parse(localStorage.getItem('push'));
  }

  ngAfterViewInit(){
    this.messages = JSON.parse(localStorage.getItem('push'));
  }

  
  onYesClick(){

      this.event.emit(true);
      this.dialogRef.close();
   
  }
    
  onNoClick(){
  this.dialogRef.close();
  }
}
