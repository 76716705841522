import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material';
import { MessageDialogComponent } from './message-dialog.component';


@Injectable()
export class MessageDialogService {

    title = 'angular-message-dialog';
    
    constructor(public dialog: MatDialog) {}

  
    alertDialog(text: string){
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: '350px',
        data: text
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }
}