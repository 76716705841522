import { TextMaskModule } from 'angular2-text-mask';
import { MatSelectFilterModule } from 'mat-select-filter';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { APP_BASE_HREF, CurrencyPipe, DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MAT_DATE_LOCALE } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AuthGuard } from './_auth/auth.guard';
import { AuthService } from './_auth/auth.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { Error404Component } from './components/error404/error404.component';
import { LayoutsModule } from './components/layouts/layouts.module';
import { AnimationsModule } from './core/animations/animation.module';
import { DirectivesModule } from './core/directives/directives.module';
import { CustomMaterialModule } from './core/modules/material.module';
import { NotificationService } from './core/notification/notification-service.service';
import { PipeModule } from './core/pipe/pipe.module';
import { SessionStorageControle } from './core/session-storage-controle';
import { CancelarColetasDialogComponent } from './core/shared/cancelar-coletas-dialog/cancelar-coletas-dialog.component';
import { CancelarColetasDialogService } from './core/shared/cancelar-coletas-dialog/cancelar-coletas-dialog.service';
import { ChangePasswordDialogComponent } from './core/shared/change-password-dialog/change-password-dialog.component';
import { ChangePasswordDialogService } from './core/shared/change-password-dialog/change-password-dialog.service';
import { ConfirmationDialogComponent } from './core/shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './core/shared/confirmation-dialog/confirmation-dialog.service';
import { DescancelarColetasDialogComponent } from './core/shared/descancelar-coletas-dialog/descancelar-coletas-dialog.component';
import { DescancelarColetasDialogService } from './core/shared/descancelar-coletas-dialog/descancelar-coletas-dialog.service';
import { EnvioConfirmationDialogComponent } from './components/coletas/envio-coletas/envio-confirmation-dialog/envio-confirmation-dialog.component';
import { EstornarColetasDialogComponent } from './core/shared/estornar-coletas-dialog/estornar-coletas-dialog.component';
import { EstornarColetasDialogService } from './core/shared/estornar-coletas-dialog/estornar-coletas-dialog.service';
import { MessageDialogComponent } from './core/shared/message-dialog/message-dialog.component';
import { MessageDialogService } from './core/shared/message-dialog/message-dialog.service';
import { NotificationDialogComponent } from './core/shared/notification-dialog/notification-dialog.component';
import { PendenciaColetasDialogComponent } from './core/shared/pendencia-coletas-dialog/pendencia-coletas-dialog.component';
import { PendenciaColetasDialogService } from './core/shared/pendencia-coletas-dialog/pendencia-coletas-dialog.service';
import { PendenciaHistoricoDialogComponent } from './core/shared/pendencia-historico-dialog/pendencia-historico-dialog.component';
import { PendenciaHistoricoDialogService } from './core/shared/pendencia-historico-dialog/pendencia-historico-dialog.service';
import { PromptDialogComponent } from './core/shared/prompt-dialog/prompt-dialog.component';
import { PromptDialogService } from './core/shared/prompt-dialog/prompt-dialog.service';
import { PushDialogComponent } from './core/shared/push-dialog/push-dialog.component';
import { PushDialogService } from './core/shared/push-dialog/push-dialog.service';
import { ResetPasswordDialogComponent } from './core/shared/reset-password-dialog/reset-password-dialog.component';
import { ResetPasswordDialogService } from './core/shared/reset-password-dialog/reset-password-dialog.service';
import { MenuService } from './services/menu.service';
import { OAuthService } from './services/oauth.service';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
		AppComponent,
		CancelarColetasDialogComponent,
		ConfirmationDialogComponent,
		DescancelarColetasDialogComponent,
		EnvioConfirmationDialogComponent,
		Error404Component,
		EstornarColetasDialogComponent,
		NotificationDialogComponent,
		PendenciaColetasDialogComponent,
		PendenciaHistoricoDialogComponent,
		PromptDialogComponent,
    ChangePasswordDialogComponent,
    MessageDialogComponent,
    PushDialogComponent,
    ResetPasswordDialogComponent,
  ],

	imports: [
		AnimationsModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		CustomMaterialModule,
		DirectivesModule,
		FlexLayoutModule,
		FormsModule,
		HttpModule,
		LayoutsModule,
		MatSelectFilterModule,
		PipeModule,
		ReactiveFormsModule,
		RouterModule,
		TextMaskModule,
		NgxMaskModule.forRoot(),
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right',
			preventDuplicates: true,
		}), // ToastrModule added
  ],
	entryComponents: [
		CancelarColetasDialogComponent,
		ConfirmationDialogComponent,
		DescancelarColetasDialogComponent,
		EnvioConfirmationDialogComponent,
		EstornarColetasDialogComponent,
		NotificationDialogComponent,
		PendenciaColetasDialogComponent,
		PendenciaHistoricoDialogComponent,
		PromptDialogComponent,
    ChangePasswordDialogComponent,
    MessageDialogComponent,
    PushDialogComponent,
    ResetPasswordDialogComponent,
  ],

	providers: [
		AppConfig,
		AuthGuard,
		AuthService,
		CancelarColetasDialogService,
		ChangePasswordDialogService,
		ConfirmationDialogService,
		CurrencyPipe,
		DescancelarColetasDialogService,
		EstornarColetasDialogService,
		MenuService,
		MessageDialogService,
		NotificationService,
		OAuthService,
		PendenciaColetasDialogService,
		PendenciaHistoricoDialogService,
		PromptDialogService,
		PushDialogService,
		ResetPasswordDialogService,
		SessionStorageControle,
		{
			provide: APP_INITIALIZER,
			useFactory: (config: AppConfig) => () => config.load(),
			deps: [AppConfig],
			multi: true,
		},
		{
			provide: MAT_DATE_LOCALE,
			useValue: 'pt-br',
		},
		{
			provide: LOCALE_ID,
			useValue: 'pt',
		},
		{
			provide: APP_BASE_HREF,
			useValue: '/',
		},
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
