import { Injectable } from '@angular/core';
import { BaseServicePadrao } from '../core/base-service-padrao';
import { catchError, map, timeout } from 'rxjs/operators';
import { Http } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends BaseServicePadrao {

  constructor(private http: Http) {
    super(http, "authentication");
   }

  revoke() {
    this.checkToken();
    return this.http.get(this.urlBase+"/revoke", this.options)
    .pipe(timeout(40000), map(dados => this.wsHandler.handle(dados)), 
          catchError(this.handleError));
  }
  
}
