import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanActivateChild, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map, take } from 'rxjs/operators';
import { Role } from './users';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
 //   console.log(sessionStorage.getItem('login'))
    if(sessionStorage.getItem('login') === "true" ){
      this.authService.setLoggedIn(true);
    }
    if(sessionStorage.getItem('certified') === "true" ){
      this.authService.setCertification(true);
    }
  }
 
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
 
     return this.authService.isLogin     
      .pipe( 
        take(1), 
          map((isLoggedIn: boolean) => {       
            if(sessionStorage.getItem('role')!="ROLES_DEBUGGER_ADMIN"){
              const roles = next.data && next.data.roles as Role[];
              if (roles && !roles.some(r => this.authService.hasRole(r))) {
                this.router.navigate(['home']);  
       //         console.log("Voltar para home");
              }
            }
            if (!isLoggedIn){
              
            this.router.navigate(['sessao']);  
            this.authService.logout();
            return false;
          }
       /*   
       if(sessionStorage.getItem("login") !== null){
            this.router.navigate(['sessao']);  
            this.authService.logout();
            return false;

          }*/
          return true;
        })
      );
    
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

   /* // redirect and return false
    if (this.authService.getCertification()) {
      this.router.navigate(['prova']);
      console.log(this.authService.getCertification);
      return false;
    }
    return true;
  }*/
  
  return this.authService.isCertification.        
  pipe( 
    take(1), 
      map((isCert: boolean) => {         
      if (!isCert){
        this.router.navigate(['prova']);  
        return false;
      }
      return true;
    })
  );
}

}
