import { AbstractControl } from '@angular/forms';

export class Validacoes {

    static ValidaCpf(controle: AbstractControl) {
        let cpf = controle.value;

        let soma: number = 0;
        let resto: number;
        let valido: boolean;

        if (cpf === null) {
            valido = true;
          
        } else {
            cpf = cpf.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '').replace(/_/g, '');
           
    
            const regex = new RegExp('[0-9]{11}');

            if (
                cpf == '00000000000' ||
                cpf == '11111111111' ||
                cpf == '22222222222' ||
                cpf == '33333333333' ||
                cpf == '44444444444' ||
                cpf == '55555555555' ||
                cpf == '66666666666' ||
                cpf == '77777777777' ||
                cpf == '88888888888' ||
                cpf == '99999999999' ||
                !regex.test(cpf)
            )
                valido = false;
            else {
                valido = true;

                let numbers = cpf.substring(0, 9);
                let digits = cpf.substring(9);
           
                for (let i = 10; i > 1; i--)
                soma += numbers.charAt(10 - i) * i;
                resto = soma % 11 < 2 ? 0 : 11 - (soma % 11);

                if (resto !== Number(digits.charAt(0))) valido = false;
                numbers = cpf.substring(0, 10);
                soma = 0;

                for (let i = 11; i > 1; i--)
                soma += numbers.charAt(11 - i) * i;
                resto = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        
                if (resto !== Number(digits.charAt(1))) valido = false;

            }
        }
        if (valido) return null;
   
        
        return { cpfInvalido: true };
    }

    
    static CNH(controle: AbstractControl) {

        let text = controle.value;

        if (text == null) return null;

        if (text.trim().length < 11) return { required: true };

        let char1 = text.indexOf(0);
        let count: number = 0;
        if(char1 == '0'){
           count = 1;
        }
        
        let cnh: string = text.substring(count, 9);
        let digito: string = text.substring(text.trim().length -2);

        /* Digito um */
        let soma: number = 0, j = cnh.length;

		for (let i = 0; i <  cnh.length; ++i, --j) {
            let d1 = (cnh.charCodeAt(i)- 48);
            let results: number = d1  * j;
            soma += results;
		}

        let dsc = 0; 
        let digitoUm = soma % 11;
        
        if (digitoUm  >= 10) {
			digitoUm  = 0;
			dsc = 2;
        }
        
        /* Digito Dois */
        soma = 0;
        j = 2;
        
        for (let i = 0; i < cnh.length; ++i, ++j) {
			soma += ((cnh.charCodeAt(i) - 48)  * j);
		}

        let x = soma % 11;

        let digitoDois  = (x >= 10) ? 0 : x - dsc;

        let digitoEncontrado: string = digitoUm.toString() + digitoDois.toString();

       if (digito == digitoEncontrado)  return null;
       
       return { cnhInvalido: true }

    }


    static ValidaCodigoAmostra(controle: AbstractControl) {

        let codigoAmostra = controle.value;
        let codigo: string = localStorage.getItem('matriz'); 

        
        let valido: boolean;

        if (codigoAmostra === null) {
            valido = true;
          
        } else {

                valido = false;

                if(codigo === "cabelo"){
                    if(codigoAmostra.indexOf("C") !== -1){
                    valido = true;
                    }
                }
                if(codigo === "urina"){
                    if(codigoAmostra.indexOf("U") !== -1){
                        valido = true;
                    }
                 }
                if(codigo === "saliva"){
                    if(codigoAmostra.indexOf("S") !== -1){
                    valido = true;
                    }
                }
        }
        
        if (valido) return null;
   
        
        return { required: true, codigoAmostraInvalido: true };   
    }



    
    static ValidaCPFDoadorTestemunha(controle: AbstractControl) {

        let value = controle.value;
        let comp = 0;

        let coletorDuplicado: boolean = false;
        let doadorDuplicado: boolean = false;
        let testemunhaDuplicado: boolean = false;

        let coletor = sessionStorage.getItem('currentUser');
        let doador = localStorage.getItem('doadorColeta');
        let testemunha = localStorage.getItem('testemunhaColeta');
        
        let valido: boolean;

        if (value === null) {
            valido = true;
          
        } else {
                value =  value.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '');
                valido = true;

                if(coletor === value){
                    comp++;
                }
                
                if(doador === value ){
                    comp++;
                }

                if(testemunha === value){
                    comp++;
                }

                if( (coletor == doador) && (coletor == testemunha) ){
                    valido = false;

                    return { required: true, cpfColetorDoadorTestemunhaUtilizado: true };   
                }

                if(coletor == doador){
                    valido = false;
                    return { required: true, cpfColetorDoadorUtilizado: true };   
                }

                if(coletor == testemunha){
                    valido = false;
                    return { required: true, cpfColetorTestemunhaUtilizado: true };   
                }

                if(doador == testemunha){
                    valido = false;
                    return { required: true, cpfDoadorTestemunhaUtilizado: true };   
                }
             
        }
    
        if (valido) return null;
   
        
    }

    static ValidaCnpj(controle: AbstractControl) {
        let cnpj = controle.value;

        let valido: boolean;


        if (cnpj === null) {
            valido = true;
            return null;
        } else {
            cnpj = cnpj.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '').replace(/_/g, '');
          
            const regex = new RegExp('[0-9]{14}');

            if (
                cnpj == '00000000000000' ||
                cnpj == '11111111111111' ||
                cnpj == '22222222222222' ||
                cnpj == '33333333333333' ||
                cnpj == '44444444444444' ||
                cnpj == '55555555555555' ||
                cnpj == '66666666666666' ||
                cnpj == '77777777777777' ||
                cnpj == '88888888888888' ||
                cnpj == '99999999999999' ||
                !regex.test(cnpj)
            )
                valido = false;
            else {
                valido = true;

                let tamanho = cnpj.length - 2;
                let numeros = cnpj.substring(0, tamanho);
                let digitos = cnpj.substring(tamanho);
                let soma = 0;
                let pos = tamanho - 7;

                for (let i = tamanho; i >= 1; i--) {
                    soma += numeros.charAt(tamanho - i) * pos--;
                    if (pos < 2) pos = 9;
                }

                let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

                if (resultado != digitos.charAt(0)) {
                    valido = false;
                }

                tamanho = tamanho + 1;
                numeros = cnpj.substring(0, tamanho);
                soma = 0;
                pos = tamanho - 7;

                for (let i = tamanho; i >= 1; i--) {
                    soma += numeros.charAt(tamanho - i) * pos--;
                    if (pos < 2) pos = 9;
                }
                resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
                if (resultado != digitos.charAt(1)) {
                    valido = false;
                }

            }
        }
        if (valido) {
            return null;
        }

        return { required: true, cnpjInvalido: true };

    }

    
    static ValidaCpfCnpj(controle: AbstractControl) {
        let text = controle.value;

        let valido: boolean;
        let soma: number = 0;
        let resto: number;


        if (text === null) {
            valido = true;
            return null;
        } else {
            text = text.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '').replace(/_/g, '');
            if (text.length <= 11) {
                let cpf = text;



                const regex = new RegExp('[0-9]{11}');

                if (
                    cpf == '00000000000' ||
                    cpf == '11111111111' ||
                    cpf == '22222222222' ||
                    cpf == '33333333333' ||
                    cpf == '44444444444' ||
                    cpf == '55555555555' ||
                    cpf == '66666666666' ||
                    cpf == '77777777777' ||
                    cpf == '88888888888' ||
                    cpf == '99999999999' ||
                    !regex.test(cpf)
                )
                {
                 valido = false;
                }
                else {
                    valido = true;
    
                    let numbers = cpf.substring(0, 9);
                    let digits = cpf.substring(9);
               
                    for (let i = 10; i > 1; i--)
                    soma += numbers.charAt(10 - i) * i;
                    resto = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    
                    if (resto !== Number(digits.charAt(0))) valido = false;
                    numbers = cpf.substring(0, 10);
                    soma = 0;
    
                    for (let i = 11; i > 1; i--)
                    soma += numbers.charAt(11 - i) * i;
                    resto = soma % 11 < 2 ? 0 : 11 - (soma % 11);
            
                    if (resto !== Number(digits.charAt(1))) valido = false;
    
                }
              
            } else {
                let cnpj = text;
                const regex = new RegExp('[0-9]{14}');

                if (
                    cnpj == '00000000000000' ||
                    cnpj == '11111111111111' ||
                    cnpj == '22222222222222' ||
                    cnpj == '33333333333333' ||
                    cnpj == '44444444444444' ||
                    cnpj == '55555555555555' ||
                    cnpj == '66666666666666' ||
                    cnpj == '77777777777777' ||
                    cnpj == '88888888888888' ||
                    cnpj == '99999999999999' ||
                    !regex.test(cnpj)
                ){ valido = false;}
                else {
                    valido = true;
    
                    let tamanho = cnpj.length - 2;
                    let numeros = cnpj.substring(0, tamanho);
                    let digitos = cnpj.substring(tamanho);
                    let soma = 0;
                    let pos = tamanho - 7;
    
                    for (let i = tamanho; i >= 1; i--) {
                        soma += numeros.charAt(tamanho - i) * pos--;
                        if (pos < 2) pos = 9;
                    }
    
                    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    
                    if (resultado != digitos.charAt(0)) {
                        valido = false;
                    }
    
                    tamanho = tamanho + 1;
                    numeros = cnpj.substring(0, tamanho);
                    soma = 0;
                    pos = tamanho - 7;
    
                    for (let i = tamanho; i >= 1; i--) {
                        soma += numeros.charAt(tamanho - i) * pos--;
                        if (pos < 2) pos = 9;
                    }
                    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
                    if (resultado != digitos.charAt(1)) {
                        valido = false;
                    }
    
                }
            }
            if (valido) {
                return null;
            }
    
            return { required: true, cpfCnpjInvalido: true };

            }
            
    }


    static MaiorQue18Anos(controle: AbstractControl) {
        const nascimento = controle.value;
        const [ano, mes, dia] = nascimento.split('-');
        const hoje = new Date();
        const dataNascimento = new Date(ano, mes, dia, 0, 0, 0);
        const tempoParaTeste = 1000 * 60 * 60 * 24 * 365 * 18; //18 anos em mili segundos...

        if (hoje.getTime() - dataNascimento.getTime() >= tempoParaTeste)
            return null;

        return { menorDeIdade: true };
    }

    static SenhasCombinam(controle: AbstractControl) {
        let senha = controle.get('newsenha').value;
        let confirmarSenha = controle.get('newsenha').value;

        if (senha === confirmarSenha) return null;

        controle.get('confirmarSenha').setErrors({ senhasNaoCoincidem: true });
    }
}