import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preloading-spinner',
  templateUrl: './preloading-spinner.component.html',
  styleUrls: ['./preloading-spinner.component.css'],
  
})
export class PreloadingSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
