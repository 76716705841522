import { Component, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from './app.config';
import { AuthenticationService } from './services/authentication.service';
import { AuthService } from './_auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
/* @HostListener('window:unload', ['$event'])
 unloadHandler($event) {
$event.preventDefault();
this.logout();

}


@HostListener("window:beforeunload", [ "$event" ])
beforeUnloadHander($event) {
  let validNavigation: boolean = false;

  sessionStorage.setItem("evento",JSON.stringify($event));
  $event.preventDefault();

  return ; 
// ...
}*/
  title = 'innovatox-sistema-ui';


  constructor(
    public router: Router,
    public config: AppConfig, 
    private auth: AuthService, 
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService) {}

    ngOnInit(){
      localStorage.removeItem("before"); 
      localStorage.removeItem("current");  
      this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          localStorage.setItem("before",localStorage.getItem("current")); 
          localStorage.setItem("current",e.url);  
        }
      });
      if ( (this.config.getEnv('env') =='production') || (this.config.getEnv('env') =='homolog') ) {
        if (location.protocol === 'http:') {
          window.location.href = location.href.replace('http', 'https');
        }
      }
      
    }
    

    
  logout() {
    let login = sessionStorage.getItem("login");
    if(login == "true"){
    this.authenticationService.revoke().subscribe();
      this.auth.logout();
    }
  }
}

