import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PromptDialogComponent } from './prompt-dialog.component';
import { CustomPromptnDialogData } from 'src/app/interfaces/custom-dialog-data';

@Injectable()
export class PromptDialogService {
	title = 'angular-prompt-dialog';
	dialogCustomData: CustomPromptnDialogData;

	constructor(public dialog: MatDialog) {
		this.dialogCustomData = {
			width: '450px',
			data: {
				title: 'Prompt',
				text: 'Texto de solicitação do prompt',
				request_text: false,
				confirm: 'Enviar'
			},
		};
	}

	set(obj, target) {
		for (let ind in obj) {
			if (target.hasOwnProperty(ind)) {
				if (typeof obj[ind] === 'object' && obj[ind] !== null && typeof target[ind] === 'object' && target[ind] !== null) {
					this.set(obj[ind], target[ind]);
				} else {
					target[ind] = obj[ind];
				}
			}
		}
	}

	openDialog(customData: CustomPromptnDialogData) {
		this.set(customData, this.dialogCustomData);
		const dialogRef = this.dialog.open(PromptDialogComponent, this.dialogCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}
}
