import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { NotificationService } from 'src/app/core/notification/notification-service.service';

import { ColetasPendenciaService } from 'src/app/services/coletas-pendencia.service';
import { ColetasRecoletaService } from 'src/app/services/coletas-recoleta.service';
import { EmailService } from 'src/app/services/email.service';

@Component({
	selector: 'app-pendencia-coletas-dialog',
	templateUrl: './pendencia-coletas-dialog.component.html',
	styleUrls: ['./pendencia-coletas-dialog.component.scss'],
})
export class PendenciaColetasDialogComponent implements OnInit {
	public lstOrigem: any;
	public origemSet: boolean = false;
	public lstAllPendencias: any;
	public lstValidPendencias: any;
	public lstPendencias = [];
	public isRecoleta: boolean = false;
	public lockBtn: boolean = false;
	public dataPendencias: MatTableDataSource<any>;
	public columnsToDisplay: string[] = ['origem', 'pendencia', 'solucao', 'acao'];
	public columnsToDisplayView: string[] = ['Origem', 'Pendencia', 'Solução', 'Ação'];

	event: EventEmitter<any> = new EventEmitter();

	public formulario: FormGroup = new FormGroup({
		origem: new FormControl(null, Validators.required),
		id_pendencia: new FormControl(null, Validators.required),
	});

	public pendencias: FormGroup = new FormGroup({
		id_coleta: new FormControl(this.coleta.id, Validators.required),
		id_perfil: new FormControl(parseInt(sessionStorage.getItem('perfil')), Validators.required),
		pendencia: new FormControl(null),
	});

	constructor(
		private coletasPendenciaService: ColetasPendenciaService,
		private coletasRecoletaService: ColetasRecoletaService,
		private emailService: EmailService,
		private toastr: NotificationService,
		public changeRef: ChangeDetectorRef,
		public dialogRef: MatDialogRef<PendenciaColetasDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public coleta: any
	) { }

	async ngOnInit() {
		let dados = await this.coletasPendenciaService.getMotivosPendencias().toPromise();
		this.lstAllPendencias = dados.object;
		this.lstOrigem = [...new Set(this.lstAllPendencias.map((item) => item.origem))];
	}

	changeOrigem() {
		this.origemSet = true;
		this.get_lstValidPendencias();
	}

	get_lstValidPendencias() {
		this.lstValidPendencias = [];
		Object.keys(this.lstAllPendencias).forEach((key) => {
			if (this.lstAllPendencias[key] != undefined && (this.lstAllPendencias[key].finalidade == null || this.lstAllPendencias[key].finalidade.toUpperCase().includes(this.coleta.finalidade.toUpperCase())) && this.lstAllPendencias[key].origem == this.formulario.get('origem').value) {
				this.lstValidPendencias.push(this.lstAllPendencias[key]);
			}
		});
	}

	addPendencia() {
		this.lstPendencias.unshift(this.lstAllPendencias.find((pendencia) => pendencia.id == this.formulario.get('id_pendencia').value));
		this.origemSet = false;
		this.formulario.reset();
		this.dataPendencias = new MatTableDataSource(this.lstPendencias);
		this.verifyRecoleta();
	}

	deletPendencia(pendencia) {
		this.lstPendencias = this.lstPendencias.filter((element) => element.id != pendencia.id);
		this.dataPendencias = new MatTableDataSource(this.lstPendencias);
		this.verifyRecoleta();
	}

	isSelected(pendencia) {
		return this.lstPendencias.includes(pendencia);
	}

	verifyRecoleta() {
		this.lstPendencias.filter((element) => element.correcao == 'Recoleta').length > 0 ? (this.isRecoleta = true) : (this.isRecoleta = false);
	}

	async clickPendencia() {
		this.lockBtn = true;
		this.pendencias.controls['pendencia'].setValue(this.lstPendencias);
		this.coletasPendenciaService.postPendencia(this.pendencias.value).subscribe(
			async (post) => {
				if (post.object.success) {
					this.toastr.showSuccess('A pendência foi aplicada com sucesso', 'Sucesso');
					// * COLOCADO COMO FUTURA MELHORIA DE PROCESSO
					// let notify = await this.emailService.notifyApplyPendencia(this.pendencias.get('id_coleta').value).toPromise();
					// if (notify.object.success) {
					// 	this.toastr.showSuccess('Email de notificação de pendência enviado', 'Sucesso');
					// } else {
					// 	this.toastr.showWarning('Falha no envio do email de notificação de pendência.', 'Aviso');
					// }
					this.event.emit(true);
					this.dialogRef.close();
				} else {
					this.toastr.showError('Erro ao aplicar a pendência', 'Erro');
					this.lockBtn = false;
					console.error(post);
				}
			},
			(error) => {
				this.toastr.showError('Erro ao solicitar a aplicar da pendência', 'Erro');
				this.lockBtn = false;
				console.error(error);
			},
		);
	}

	async clickRecoleta() {
		let lstRecoleta = this.lstPendencias.filter((obj) => obj.correcao == 'Recoleta').map((obj) => obj.id);
		this.pendencias.controls['pendencia'].setValue(lstRecoleta);
		this.coletasRecoletaService.postRecoleta(this.pendencias.value).subscribe(
			(post) => {
				if (post.object.success) {
					this.toastr.showSuccess('A recoleta foi aplicada com sucesso', 'Sucesso');
					this.event.emit(true);
					this.dialogRef.close();
				} else {
					this.toastr.showError('Erro ao aplicar a recoleta', 'Erro');
					console.error(post);
				}
			},
			(error) => {
				this.toastr.showError('Erro ao solicitar a aplicar da recoleta', 'Erro');
				console.error(error);
			},
		);
	}

	clickCancelar() {
		this.event.emit(false);
		this.dialogRef.close();
	}
}
