import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { PromptDialogComponent } from '../prompt-dialog/prompt-dialog.component';
import { CustomConfirmationDialogData, CustomPromptnDialogData } from 'src/app/interfaces/custom-dialog-data';

@Injectable()
export class ConfirmationDialogService {
	title = 'angular-confirmation-dialog';
	dialogCustomData: CustomConfirmationDialogData;
	confirmationCustomData: CustomConfirmationDialogData;
	promptCustomData: CustomPromptnDialogData;

	constructor(public dialog: MatDialog, private currencyPipe: CurrencyPipe) {
		this.dialogCustomData = {
			width: '450px',
			data: {
				title: 'Confirmação',
				text: 'Você quer alterar esse registro?',
				confirm: 'Sim',
				reject: 'Não',
			},
		};
		this.confirmationCustomData = {
			width: '450px',
			data: {
				title: 'Confirmação',
				text: 'Você quer alterar esse registro?',
				confirm: 'Sim',
				reject: 'Não',
			},
		};
		this.promptCustomData = {
			width: '500px',
			data: {
				title: 'Prompt',
				text: 'Você quer alterar esse registro?',
				request_text: false,
				confirm: 'Sim',
			},
		};
	}

	set(obj, target) {
		for (let ind in obj) {
			if (target.hasOwnProperty(ind)) {
				if (typeof obj[ind] === 'object' && obj[ind] !== null && typeof target[ind] === 'object' && target[ind] !== null) {
					this.set(obj[ind], target[ind]);
				} else {
					target[ind] = obj[ind];
				}
			}
		}
	}

	openDialog() {
		this.set(
			{
				width: '350px',
				data: {
					text: 'Você quer alterar esse registro?',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	confirmarDialog() {
		this.set(
			{
				data: {
					text: 'Todos os dados informados na coleta estão corretos?',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	confirmarDialogEdicao() {
		this.set(
			{
				data: {
					text: 'Todos os dados informados na alteração da coleta estão corretos?',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	confirmarDialogAmostraRecebida() {
		this.set(
			{
				data: {
					text: 'Amostra já recebida pelo laboratório, deseja continuar a Edição?',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	confirmarDialogLiberacao(text: string) {
		this.set(
			{
				data: {
					text: 'Deseja liberar o laudo desta amostra (' + text + ')?',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	envioConsultarTodosRenach(text: string) {
		this.set(
			{
				data: {
					text: 'Deseja liberar o laudo desta amostra (' + text + ')? \n Obs. A nota fiscal também será emitida',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	/*confirmarDialogLiberacaoLote(text: string) {
		this.set({
			data: {
				text: 'Deseja liberar o laudo destas amostras?',
			},
		}, this.confirmationCustomData);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}*/

	confirmarDialogNotaEmissao(text: string) {
		this.set(
			{
				data: {
					text: 'Deseja emitir a nota fiscal de valor  ' + this.currencyPipe.transform(text, 'BRL') + ' ?',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	confirmarDialogNotaCancelamento() {
		this.set(
			{
				data: {
					text: 'Deseja cancelar esta nota fiscal?',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	/*confirmarDialogRenachColetaExclusao() {
		this.set({
			data: {
				text: 'Deseja remover a coleta do Renach',
			},
		}, this.confirmationCustomData);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}*/

	/*confirmarDialogRenachExclusao() {
		this.set({
			data: {
				text: 'Deseja remover o laudo do Renach',
			},
		}, this.confirmationCustomData);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}*/

	confirmarDialogRenachEnvio() {
		this.set(
			{
				data: {
					text: 'Os dados informados estão corretos para remeter ao Renach?',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	confirmarDialogLogistica() {
		this.set(
			{
				data: {
					text: 'Deseja notificar o não recebimento dessa amostra?',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	confirmarDialogRecebimento() {
		this.set(
			{
				data: {
					text: 'Todos os dados informados no recebimento estão corretos?',
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	/*confirmarDialogDescancelamentoAmostra() {
		this.set({
			data: {
				text: 'Deseja descancelar essa amostra?',
			},
		}, this.confirmationCustomData);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}*/

	alertDialog(text: string) {
		this.set(
			{
				data: {
					text: text,
				},
			},
			this.confirmationCustomData,
		);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	customConfirmationDialog(customData: CustomConfirmationDialogData) {
		this.set(customData, this.confirmationCustomData);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	customPromptDialog(customData: CustomPromptnDialogData) {
		this.set(customData, this.promptCustomData);
		const dialogRef = this.dialog.open(PromptDialogComponent, this.promptCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}
}
