import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotificationService } from 'src/app/core/notification/notification-service.service';

import { ColetasService } from 'src/app/services/coletas.service';

@Component({
  selector: 'app-descancelar-coletas-dialog',
  templateUrl: './descancelar-coletas-dialog.component.html',
  styleUrls: ['./descancelar-coletas-dialog.component.scss']
})
export class DescancelarColetasDialogComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();

  public formulario: FormGroup = new FormGroup({
    'id': new FormControl(this.cancelamento.id, Validators.required),
    'id_perfil_anulacao': new FormControl(parseInt(sessionStorage.getItem("accredited")), Validators.required),
    'motivo_anulacao': new FormControl(null, Validators.required),
  });

  constructor(
    public dialogRef: MatDialogRef<DescancelarColetasDialogComponent>,
    public changeRef: ChangeDetectorRef,
    private coletasService: ColetasService,
    private toastr: NotificationService,
    @Inject(MAT_DIALOG_DATA) public cancelamento: any) {
  }

  ngOnInit() {
  }

  onYesClick(): void {
    this.coletasService.descancelar(this.formulario.value).subscribe(retorno => {
      if (retorno.httpStatus === 200) {
        this.toastr.showSuccess("O cancelamento foi anulado com sucesso", "Sucesso");
        this.event.emit(true);
        this.dialogRef.close();
      } else {
        this.toastr.showError("Erro anular cancelamento", "Erro");
      }
    });
  }

  onNoClick(): void {
    this.event.emit(false);
    this.dialogRef.close();
  }
}
