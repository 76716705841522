import { Injectable, NgModule } from "@angular/core";
import { NgModel } from "@angular/forms";
import { MatDialog, MatDialogRef } from '@angular/material';
import { DescancelarColetasDialogComponent } from './descancelar-coletas-dialog.component';


//@Injectable()

@Injectable({
  providedIn: 'root'
})
export class DescancelarColetasDialogService {
    constructor(public dialog: MatDialog) {}

    descancelarColetaDialog(id_cancelamento){
      const dialogRef = this.dialog.open(DescancelarColetasDialogComponent, {
        width: '550px', 
        data: id_cancelamento
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }
}
