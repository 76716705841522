import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { fadeOutAnimation } from '../../../core/animations';


@Component({
  selector: 'app-sessao-layout',
  templateUrl: './sessao-layout.component.html',
  styleUrls: ['./sessao-layout.component.scss'],
  animations: [fadeOutAnimation]
})
export class SessaoLayoutComponent {

loading: boolean = true;

  constructor(
    private router: Router,

  ) {}

  ngOnInit(){
    this.functionLoader();
  }
  
  
  
  functionLoader() {  
    setTimeout (() => {
      this.loading = false;
      //console.log("Loading: "+ this.loading);
      }, 1300);
  }
}
