import { Component, OnInit, Inject, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {

  public cpfcnpjmask = function (rawValue) {
    var numbers = rawValue.match(/\d/g);
    var numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 11) {
      return [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    } else {
      return [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/,  /[0-9]/, '-', /[0-9]/, /[0-9]/];
    }
  }
  constructor(
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>, 
    public changeRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

   
    public formulario: FormGroup = new FormGroup({
      'usuario': new FormControl(null, [Validators.required, Validators.minLength(14), Validators.maxLength(18)]),
      'retorno': new FormControl(false)
    })

  ngOnInit() {
  }

  event: EventEmitter<any> = new EventEmitter();

  onYesClick(){
    this.formulario.get('retorno').setValue(true);
    this.formulario.get('usuario').setValue(this.formulario.get('usuario').value.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '').replace(/_/g, ''));
    this.event.emit(this.formulario.value);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.formulario.get('retorno').setValue(false);
    this.event.emit(this.formulario.value); 
    this.dialogRef.close();
  }



}
