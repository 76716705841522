import { Component, OnInit, Inject, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>, 
    public changeRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  event: EventEmitter<any> = new EventEmitter();

  onYesClick(){
    this.event.emit(true);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.event.emit(false); 
    this.dialogRef.close();
  }
}
