import { Component, OnInit, Inject, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent implements OnInit {
  blockSubmit: boolean;
  textareaControl: FormControl;
  event: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<PromptDialogComponent>, 
    public changeRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (this.data.request_text) {
        this.textareaControl = new FormControl('', [Validators.required, Validators.maxLength(200)]);
      } else {
        this.textareaControl = new FormControl('', [Validators.maxLength(200)]);
      }
      this.blockSubmit = !this.textareaControl.valid;
  }

  ngOnInit() {
    this.textareaControl.valueChanges.subscribe(() => {
      this.blockSubmit = !this.textareaControl.valid;
    });
  }

  onSendClick() {
    this.event.emit({
      submit: true,
      text:this.textareaControl.value
    });
    this.dialogRef.close();
  }

  onCancelClick(): void {
    this.event.emit({
      submit: false
    }); 
    this.dialogRef.close();
  }
}
