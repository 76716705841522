import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotificationService } from 'src/app/core/notification/notification-service.service';

import { ColetasService } from 'src/app/services/coletas.service';

@Component({
	selector: 'app-cancelar-coletas-dialog',
	templateUrl: './cancelar-coletas-dialog.component.html',
	styleUrls: ['./cancelar-coletas-dialog.component.scss'],
})
export class CancelarColetasDialogComponent implements OnInit {
	public lstMotivos: any;

	event: EventEmitter<any> = new EventEmitter();

	public formulario: FormGroup = new FormGroup({
		id_coleta: new FormControl(this.coleta, Validators.required),
		id_perfil: new FormControl(parseInt(sessionStorage.getItem('perfil')), Validators.required),
		motivo: new FormControl(null, Validators.required),
		descricao: new FormControl(null),
	});

	constructor(public dialogRef: MatDialogRef<CancelarColetasDialogComponent>, public changeRef: ChangeDetectorRef, private coletasService: ColetasService, private toastr: NotificationService, @Inject(MAT_DIALOG_DATA) public coleta: any) {}

	ngOnInit() {
		this.coletasService.getMotivosCancel().subscribe((dados) => {
			this.lstMotivos = dados.object;
		});
	}

	changeMotivo() {
		if (this.formulario.get('motivo').value == 'Outro') {
			this.formulario.get('descricao').setValidators([Validators.required]);
		} else {
			this.formulario.get('descricao').clearValidators();
		}
		this.formulario.get('descricao').updateValueAndValidity();
	}

	onYesClick() {
		this.coletasService.cancelar(this.formulario.value).subscribe((retorno) => {
			if (retorno.httpStatus === 200) {
				this.toastr.showSuccess('O cancelamento foi realizado com sucesso', 'Sucesso');
				this.event.emit(true);
				this.dialogRef.close();
			} else {
				this.toastr.showError('Erro realizar o cancelamento', 'Erro');
			}
		});
	}

	onNoClick(): void {
		this.event.emit(false);
		this.dialogRef.close();
	}
}
