import { Injectable } from "@angular/core";
import { MatDialog } from '@angular/material';
import { PendenciaHistoricoDialogComponent } from './pendencia-historico-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PendenciaHistoricoDialogService {
  constructor(public dialog: MatDialog) {}

  pendenciaHistoricoDialog(pendencia){
    const dialogRef = this.dialog.open(PendenciaHistoricoDialogComponent, {
      width: '1200px',
      data: pendencia
    });
    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;
  }
}
