import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Role, Roles } from './users';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  private certification = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router
  ) { }

  getToken() {
    return  window.sessionStorage.getItem("token")
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }


  get isLogged() {
    if(this.getToken() !== null)
    {
      return true;
    } else {
      return false;
    }
  }

  get isLogin() {
    return this.loggedIn.asObservable();
  }

  get isCertification() {
    return this.certification.asObservable();
  }

  hasRole(role: Role) {
    let roles:  boolean = false;
    let rolesJson: Roles[] = JSON.parse(localStorage.getItem("role"));
    for(let i = 0; i<rolesJson.length; i++){
    //  console.log(role +" - "+ Role[rolesJson[i].role]);
      if(role == Role[rolesJson[i].role]){
        roles = true;
        break;
      }
    }
    return this.isLoggedIn() && roles;//Role[this.role] === role;
  }
  
  setLoggedIn(enable: boolean) {
    this.loggedIn.next(enable);
  }
  
  setCertification(enable: boolean) {
    this.certification.next(enable);
  }

  login() {
    this.loggedIn.next(true);
    this.router.navigate(['/home']);

    //  console.log("Entrou");

  }

  logout() {       
    this.loggedIn.next(false);
    this.certification.next(false);
    localStorage.removeItem("role");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("login");
    sessionStorage.removeItem("certified");
    sessionStorage.removeItem("perfil");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("menu");
    sessionStorage.removeItem("razao");
    sessionStorage.removeItem("authority");
    sessionStorage.removeItem("accredited");
    sessionStorage.removeItem("currentUser");
    sessionStorage.removeItem("token_type");
    sessionStorage.removeItem("token_user");
    sessionStorage.removeItem("expires_in");
    this.router.navigate(['sessao']);
  }
}
