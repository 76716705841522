import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() flipped: boolean = false;

  @Input() ano: string = "00";

  @Input() mes: string = "00";

  @Input() nomeSobrenome: string = "NOME SOBRENOME";

  @Input() numeroCartao: string = "0000000000000000";

  @Input() numeroCv: string = "000";

  @Input() bandeiras: string = "";

  constructor() { }

  ngOnInit() {

  }

  flipIt() {
      this.flipped = !this.flipped;

  }

}
