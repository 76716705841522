import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions} from '@angular/http';
import { BaseServicePadrao } from '../core/base-service-padrao';
import { OAuth } from '../interfaces/oauth';

import { map } from 'rxjs/operators';
import { AuthService } from '../_auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OAuthService extends BaseServicePadrao {

  oauth: OAuth;
  client_id: string = "bd3691036071c485a3b10ce2a873ca7f";
  client_secret: string = "557311df7eae2a2214d0d75e770972fe";

  urlBase: string;
  constructor(private http: Http) {
    super(http, "oauth");
    this.oauth = new OAuth();
  
  }

 
  
  getToken(usuario: string, senha: string){

    let loginHeaders: Headers = new Headers();
    let loginOptions: RequestOptions = new RequestOptions();

  
    

    let token : string ="Basic " +btoa(this.client_id + ":" + this.client_secret);
    loginHeaders.append("Authorization", token); 
    loginHeaders.append("Content-Type", "application/json");
    //loginHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    loginOptions = new RequestOptions({headers: loginHeaders});

    this.oauth.grant_type = "password";
    this.oauth.username = usuario;
    this.oauth.password = senha;
    let urlToken : string = this.urlBase;//this.urlBase.substring(0, this.urlBase.indexOf("/api"));
    urlToken = urlToken + "/token";
    return this.http.post(urlToken,this.oauth, loginOptions)
    .pipe( map(dados => this.wsHandler.handle(dados) )); 
  }
}
