import { Component, OnInit, Inject, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Validacoes } from '../../validator/validacoes';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>, 
    public changeRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

   
    public formulario: FormGroup = new FormGroup({
      'senha': new FormControl(null),
      'retorno': new FormControl(false)
    })

    public formularioView: FormGroup = new FormGroup({
      'newsenha': new FormControl(null, [Validators.required]),
      'redsenha': new FormControl(null, [Validators.required]),
    },
    { validators: Validacoes.SenhasCombinam } );

    public visiblePassNew: boolean = false;
    public visiblePassRed: boolean = false;



  ngOnInit() {
    this.newsenha = "";
    this.redsenha = "";
  }

  event: EventEmitter<any> = new EventEmitter();

  public get newsenha(){
    return this.formularioView.get('newsenha').value;
  }

  public get redsenha(){
    return this.formularioView.get('redsenha').value;
  } 

  public set newsenha(text: string){
    this.formularioView.get('newsenha').setValue(text);
  }

  public set redsenha(text: string){
    this.formularioView.get('redsenha').setValue(text);
  }
  
  onYesClick(){
    if(this.newsenha == this.redsenha){
      this.formulario.get('retorno').setValue(true);
      this.formulario.get('senha').setValue(this.formularioView.get('newsenha').value);
      this.event.emit(this.formulario.value);
      this.dialogRef.close();
    } else {

    }
  }

  onNoClick(): void {
    this.formulario.get('retorno').setValue(false);
    this.event.emit(this.formulario.value); 
    this.dialogRef.close();
  }

  viewPassNew(){
    this.visiblePassNew = !this.visiblePassNew;
  }

  viewPassRed(){
    this.visiblePassRed = !this.visiblePassRed;
  }
}
