export class OAuth {

    public grant_type: string;
    public username: string;
    public password: string;

    constructor(){}

    public getOAuth() {
        return "grant_type="+this.grant_type+"&username=" + this.username + "&password=" +this.password;
    }
}
