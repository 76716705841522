import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	selector: 'app-pendencia-historico-dialog',
	templateUrl: './pendencia-historico-dialog.component.html',
	styleUrls: ['./pendencia-historico-dialog.component.scss'],
})
export class PendenciaHistoricoDialogComponent implements OnInit {
	event: EventEmitter<any> = new EventEmitter();

	constructor(
		public dialogRef: MatDialogRef<PendenciaHistoricoDialogComponent>,
		public changeRef: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public pendencia: any
	) { }

	ngOnInit() {
	}

	getFile(base64String: string) {
		var mediaType = base64String.split(',')[0].split(':')[1].split(';')[0];
		var byteString = atob(base64String.split(',')[1]);
		var arrayBuffer = new ArrayBuffer(byteString.length);
		var int8Array = new Uint8Array(arrayBuffer);

		for (var i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}

		var blob = new Blob([int8Array], { type: mediaType, });
		var file = new File([blob], '', { type: mediaType });

		var url = URL.createObjectURL(file);
		window.open(url, '_blank');
	}


	clickClose() {
		this.dialogRef.close();
	}
}
