import { Injectable } from '@angular/core';
import { BaseServicePadrao } from '../core/base-service-padrao';
import { Http } from '@angular/http';
import { timeout, map, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ColetasPendenciaService extends BaseServicePadrao {
	urlBase: string;
	constructor(private http: Http) {
		super(http, 'coletasPendencia');
	}

	getColetasViewPendentes(conteudo: any) {
		this.checkToken();
		return this.http.post(this.urlBase + '/getColetasViewPendentes', conteudo, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	getMotivosPendencias() {
		this.checkToken();
		return this.http.get(this.urlBase + '/getMotivosPendencias/', this.options).pipe(
			timeout(20000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	postPendencia(conteudo: any) {
		this.checkToken();
		return this.http.post(this.urlBase + '/postPendencia', conteudo, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	getTermoCorrecao(id_coleta: number) {
		this.checkToken();
		window.open(this.urlBase + '/getTermo/' + id_coleta, '_blank', 'menubar=no, location=no,width=800,height=600');
	}
	
	postFileCorrecao(conteudo) {
		this.checkToken();
		return this.http.post(this.urlBase + '/postFileCorrecao/', conteudo, this.options).pipe(
			timeout(20000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	};

	getFileCorrecao(id_pendencia_status: number) {
		this.checkToken();
		return this.http.get(this.urlBase + '/getFileCorrecao/' + id_pendencia_status, this.options).pipe(
			timeout(20000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	replyFile(id_perfil: number, id_coleta_pendencia: any, response: boolean, text: string) {
		let conteudo = {
			id_perfil: id_perfil,
			id_coleta_pendencia: id_coleta_pendencia,
			response: response,
			text: text
		}
		this.checkToken();
		return this.http.post(this.urlBase + '/replyFile/', conteudo, this.options).pipe(
			timeout(20000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	putHistorico(conteudo) {
		this.checkToken();
		return this.http.post(this.urlBase + '/postHistorico/', conteudo, this.options).pipe(
			timeout(20000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	endPendencia(id_perfil: number, id_coleta_pendencia:Array<number>, text: string) {
		let conteudo = {
			id_perfil: id_perfil,
			id_coleta_pendencia: id_coleta_pendencia,
			text: text
		}
		this.checkToken();
		return this.http.post(this.urlBase + '/endPendencia/', conteudo, this.options).pipe(
			timeout(20000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}
}
