import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { fadeOutAnimation } from 'src/app/core/animations';
import { AuthService } from 'src/app/_auth/auth.service';
import { SessionStorageControle } from 'src/app/core/session-storage-controle';
import { BaseComponentPadrao } from 'src/app/core/base-component-padrao';

@Component({
  selector: 'app-avaliacao-layout',
  templateUrl: './avaliacao-layout.component.html',
  styleUrls: ['./avaliacao-layout.component.scss'],
  animations: [fadeOutAnimation]
})
export class AvaliacaoLayoutComponent extends BaseComponentPadrao implements OnInit {
  loading: boolean = true;
  
  routerLinkActive: string | string[]
  public namePerson: string;
  public menus: any [] | String;
  public id_perfil: string;
  constructor(private sessionStorageControle: SessionStorageControle,
   private router: Router,
   private activeRoute: ActivatedRoute,
   private  sessao: AuthService) {
    super();
 }
   ngOnInit(){
  
    this.functionLoader();

  }
  
    
  logout(){
    this.sessao.logout();
   }

  
  functionLoader() {  
    this.loading = false; 
    if(this.sessionStorageControle.carregar('menu') != undefined){
        this.menus = [] =  JSON.parse( this.sessionStorageControle.carregar('menu') );
    }
      this.id_perfil = this.sessionStorageControle.carregar("perfil");
  //    console.log("Loading: "+ this.loading);
     this.namePerson = sessionStorage.getItem('razao') + " - " +sessionStorage.getItem('name');
    
  }
 
  
}
