import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CNPJMaskPipe } from './cnpj-mask.pipe';
import { CreditCardMaskPipe } from './credit-card-mask.pipe';
import { CpfCnpjDirective } from './cpf-cpnj';
import { DataMask } from './dataMask';
import { TelefoneMask } from './telefoneMask';
import { CnpjMask } from './cnpjMask';
import { NumbersOnly } from './NumbersOnly';
import { CurrencyFormatterDirective } from './CurrencyFormatterDirective';



@NgModule({
  declarations: [
    CnpjMask,
    TelefoneMask,
    DataMask,
    CpfCnpjDirective,
    NumbersOnly,
    CNPJMaskPipe,
    CreditCardMaskPipe,
    CurrencyFormatterDirective,
  ],
  imports: [
    CommonModule
  ],
  exports:[
    NumbersOnly,
  ],
  providers: [  
    CNPJMaskPipe,
    CreditCardMaskPipe,
    DatePipe,
]
})
export class DirectivesModule { }
