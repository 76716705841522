import { Injectable, NgModule } from "@angular/core";
import { NgModel } from "@angular/forms";
import { MatDialog, MatDialogRef } from '@angular/material';
import { EstornarColetasDialogComponent } from './estornar-coletas-dialog.component';


//@Injectable()

@Injectable({
  providedIn: 'root'
})
export class EstornarColetasDialogService {
    constructor(public dialog: MatDialog) {}

    estornarColetaDialog(coleta){
      const dialogRef = this.dialog.open(EstornarColetasDialogComponent, {
        width: '700px', 
        data: coleta
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }
}
