import { NgModule } from "@angular/core";
import { SafePipe } from "./SafePipe";
import { DateFormatPipe } from "./dateFormat";
import { CPFFormatPipe } from './cpfFormat';
import { CNPJFormatPipe } from './cnpjFormat';
import { DecimalPipe } from '@angular/common';

@NgModule({
    imports: [],
    declarations: [ SafePipe, DateFormatPipe, CPFFormatPipe, CNPJFormatPipe],
    providers: [DecimalPipe],
    entryComponents: [],
    exports: [  SafePipe, DateFormatPipe, CPFFormatPipe, CNPJFormatPipe ]
}) export class PipeModule{

    static forRoot(){
        return {
            ngModule: PipeModule,
            providers: [],
        };
    }
}